<template>
    <div>
        <CRow>
            <CCol sm="12" lg="6" align="center">
                <CCard>
                    <CCardHeader>Total Earned</CCardHeader>
                    <CCardBody>
                       {{this.data_user.total_mining_matching_earning}}  BTC
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="12" lg="6" align="center">
                <CCard>
                    <CCardHeader>Total Paid</CCardHeader>
                    <CCardBody>
                        {{this.data_user.total_mining_matching_earning}}  BTC
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" align="center">
                                <img src="../../../../assets/img/cap/cap-10.png" width="150">
                            </CCol>
                            <CCol sm="12" align="center">
                                Total Packages {{this.data_user.capital_earning}}
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Total Earning</h6>
                                <p>{{this.data_user.capital_earning}}  BTC</p>
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Last 24 Hours:</h6>
                               <p>{{this.data_user.capital_earning}}  BTC</p>
                            </CCol>
                             <CCol sm="12" align="center">
                                <h6>Total PAID</h6>
                                <p>{{this.data_user.capital_earning}}  BTC</p>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="12" lg="3">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" align="center">
                                <img src="../../../../assets/img/cap/cap-20.png" width="150">
                            </CCol>
                            <CCol sm="12" align="center">
                                Total Packages {{this.data_user.solitare_earning}}
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Total Earning</h6>
                                <p>{{this.data_user.solitare_earning}}  BTC</p>
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Last 24 Hours:</h6>
                                <p>{{this.data_user.solitare_earning}}  BTC</p>
                            </CCol>
                             <CCol sm="12" align="center">
                                <h6>Total PAID</h6>
                                <p>{{this.data_user.solitare_earning}}  BTC</p>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="12" lg="3">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" align="center">
                                <img src="../../../../assets/img/cap/cap-30.png" width="150">
                            </CCol>
                            <CCol sm="12" align="center">
                                Total Packages {{this.data_user.bohemian_earning}}
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Total Earning</h6>
                                <p>{{this.data_user.bohemian_earning}}  BTC</p>
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Last 24 Hours:</h6>
                                <p>{{this.data_user.bohemian_earning}}  BTC</p>
                            </CCol>
                             <CCol sm="12" align="center">
                                <h6>Total PAID</h6>
                                <p>{{this.data_user.bohemian_earning}}  BTC</p>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="12" lg="3">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" align="center">
                                <img src="../../../../assets/img/cap/cap-40.png" width="150">
                            </CCol>
                            <CCol sm="12" align="center">
                                Total Packages {{this.data_user.executive_earning}}
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Total Earning</h6>
                                <p>{{this.data_user.executive_earning}}  BTC</p>
                            </CCol>
                            <CCol sm="12" align="center">
                                <h6>Last 24 Hours:</h6>
                                <p>{{this.data_user.executive_earning}}  BTC</p>
                            </CCol>
                             <CCol sm="12" align="center">
                                <h6>Total PAID</h6>
                                <p>{{this.data_user.executive_earning}}  BTC</p>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MiningEarning',
  data(){
    return{
        urlApi:this.$store.state.urlApi,
        data_user:[]
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user-details/${localStorage.getItem('id')}`).then(response => {
      this.data_user = response.data
    })
  }
}
</script>
